const __index = {
    init() {
        __index.mainContent = new Swiper('._part1Swiper', {
            loop: true,
            pagination: '#pagination',
            paginationClickable: true,
            autoplay: 3000,
            prevButton: '.swiper-button-prev',
            nextButton: '.swiper-button-next'
        });

        new Swiper('._part2Swiper', {
            loop: true,
            pagination: '.pagination2',
            paginationClickable: true,
            autoplay: 3000
        });

        new Swiper('._zzybjSwiper', {
            loop: true,
            pagination: '.pagination3',
            paginationClickable: true,
            autoplay: 3000,
            prevButton: '.swiper-button-prev3',
            nextButton: '.swiper-button-next3'
        });
        // if (window.sessionStorage.getItem('isShow')) {
        //     $('.window_box').hide()
        // } else {
        //     $('.window_box').show()
        // }
    },
    naventerHandle() {
        var $this = $(this),
            $navLevel = $this.find('.nav-level');

        if ($navLevel.length) {
            $navLevel.show();
        }
    },
    // 关闭弹窗
    handleCloseCurrPop(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).parent(".declare-pop").hide();
    },
    navleaveHandle() {
        var $this = $(this),
            $navLevel = $this.find('.nav-level');

        if ($navLevel.length) {
            $navLevel.hide();
        }
    },
    informationTabHandle() {
        let idx = $(this).index();

        $(this).addClass("active").siblings("span").removeClass("active");
        $("._more,._hot-information-block ul").addClass('hide');
        $("._more").eq(idx).removeClass('hide');
        $("._hot-information-block ul").eq(idx).removeClass('hide');
    },
    handleCloseWindow(e) {

        $('.window_box').hide();
        window.sessionStorage.setItem('isShow', true);
        e.stopPropagation();

    },
    handleWindowJump(e) {
        e.stopPropagation();
        let type = $(this).data().type;

        if (type) {
            $('.window_box').hide();
            window.sessionStorage.setItem('isShow', true);
        }
        window.open($(this).data().href, '_blank');


    }
};

$(function () {
    __index.init();


    $(document)
        .on('click', '._closeWindow', __index.handleCloseWindow)
        .on('click', '._goLink', __index.handleWindowJump)


        .on('mouseenter', '._navList li', __index.naventerHandle)
        .on('mouseleave', '._navList li', __index.navleaveHandle)
        // 通知公告 热门资讯切换
        .on('click', '._information-tab', __index.informationTabHandle)
        // 弹窗上的关闭按钮
        .on('click', '._closePop', __index.handleCloseCurrPop);

    $('._part1Swiper').mouseenter(function () {
        __index.mainContent.stopAutoplay();
    });
    // 鼠标移出开始自动滚动
    $('._part1Swiper').mouseleave(function () {
        __index.mainContent.startAutoplay();
    });
});